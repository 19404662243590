.v20 {
  .offcanvas {
    .site-menu {
      display: flex;

      a {
        color: $color-white;
      }
    }
  }
}
