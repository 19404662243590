.ucpa-web,
.uc-site-layout {

  .site-main-tabs {
    margin: 0;

    .uk-subnav li a {
      &:hover {
        * {
          color: $color-text;
          text-decoration: underline; } } }

    h2 {
      color: $inactive;
      font-weight: 500;
      font-size: 1.2rem;
      text-transform: initial;

      @media (min-width: $tablet) {
        font-size: 1.4rem; } }

    .uk-active {
      h2 {
        color: $color-text; } } }

  .expertise-box {

    .expertise-title {
      h2 {
        max-width: 70%;
        border-bottom: 2px solid $color-text;
        margin: 15px 10px 10px 0;
        padding-bottom: 5px;

        &:hover {
          text-decoration: none;
          border-bottom: 2px solid $inactive;
          color: $inactive; } } }

    .expertise-children {
      @media (min-width: $tablet) {
        font-size: 1rem; }

      .uk-nav {
        a {
          font-weight: 500;
          display: inline-block;
          margin: 0 10px 0 0;
          padding-bottom: 3px;

          &:hover {
            text-decoration: none;
            color: $inactive; } }

        .with-children {
          margin-bottom: 10px;

          .uk-nav {
            margin-left: 20px;

            a {
              font-size: $font-size-small;

              @media (min-width: $tablet) {
                font-size: $font-size-base; } } } }

        .uk-nav { // for children of children
          a {
            font-weight: 500; } } } } }

  .person-photo {
    filter: grayscale(1) contrast(.85);

    &.color,
    &:hover {
      filter: grayscale(0) contrast(1); }

    .no-photo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid $color-text; }

    img {
      border-radius: 50%;
      width: 30px;
      height: 30px; } }

  .people-list {
    *+.uk-grid-margin {
      margin-top: 20px;

      @media (min-width: $tablet) {
        margin-top: 30px; } }

    .person-photo {
      filter: grayscale(1) contrast(.85);
      align-self: flex-start;

      &:hover {
        filter: grayscale(0) contrast(1); }

      .no-photo {
        width: 80px;
        height: 80px;

        @media (min-width: $tablet) {
          width: 120px;
          height: 120px; } }

      img {
        border-radius: 50%;
        width: 80px;
        height: 80px;

        @media (min-width: $tablet) {
          width: 120px;
          height: 120px; } } } }

  .person-profile {
    display: flex;
    align-items: center;

    .person-metadata {
      .person-fullname {
        font-weight: 700;
        font-size: $font-size-base;
        line-height: 1.2;
        display: block;
        margin-top: 10px;
        margin-bottom: 10px;

        @media (min-width: $tablet) {
          font-size: 1.1rem; } }

      .person-expertise {
        font-size: $font-size-small;
        line-height: 1.4;
        margin-top: 10px;

        ul {
          margin: 0;
          padding: 0;

          li {
            margin-right: 5px;
            display: initial; } } } } }

  .person-page {
    margin-top: 40px;

    @media (min-width: $tablet) {
      margin-top: 60px; }

    .person-page-profile {
      align-items: center;

      .person-photo {
        filter: grayscale(.25) contrast(.85);
        align-self: flex-start;

        .no-photo {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          border: 1px solid $color-text;

          @media (min-width: $tablet) {
            width: 120px;
            height: 120px; } }

        img {
          width: 100px;
          height: 100px;

          @media (min-width: $tablet) {
            width: 200px;
            height: 200px; } } }

      .person-metadata {

        .person-fullname {
          margin-bottom: 10px; }

        .person-tag {
          font-size: 1.1rem;
          margin-bottom: 10px;
          font-weight: 500;

          @media (min-width: $tablet) {
            font-size: 1.4rem; } }

        .person-expertise {
          font-size: $font-size-base;

          @media (min-width: $tablet) {
            font-size: 1.1rem; }

          ul {
            margin: 0;
            padding: 0;

            li {
              margin-right: 5px;
              display: initial; } } } } }

    .bee-data-section,
    .person-data-section {
      &+.bee-data-section,
      &+.person-data-section {
        margin-top: 30px;

        @media (min-width: $tablet) {
          margin-top: 50px; } }

      h2 {
        margin-bottom: 10px;
        font-weight: 500;

        @media (min-width: $tablet) {
          margin-bottom: 15px; } }

      h3 {
        margin: 10px 0;

        @media (min-width: $tablet) {
          margin: 20px 0 10px; } }

      ul:not([class]) {
        li {
          list-style-type: disc;
          margin-bottom: 5px; } } }

    hr {
      border-top: 2px solid $color-text;
      margin: 30px 0;

      @media (min-width: $tablet) {
        margin: 50px 0;
        border-top: 5px solid $color-text; } } }

  .record {
    &+.record {
      margin-top: 10px;

      @media (min-width: $tablet) {
        margin-top: 20px; } }

    .record-lead {
      color: $color-lead;
      font-size: 1.2rem;
      margin: 0 0 10px;
      font-weight: 500; }

    .record-title {
      font-weight: 700;
      margin-bottom: 5px;

      &.large {
        font-size: 1.2rem; }

      a {
        font-weight: 700; } }

    .record-summary {
      margin: 5px 0; }

    .record-footer {
      margin-top: 10px; }

    .record-person {
      display: flex;
      align-items: center; }

    .person-profile {
      .person-photo {
        &+.person-fullname {
          margin-left: 5px; } }

      .person-fullname {
        font-size: $font-size-tiny; } } }

  .google-cse {
    font-size: $font-size-base;
    display: none;

    // sass-lint:disable class-name-format
    .gsc-above-wrapper-area,
    .gsc-tabsArea,
    .gsc-thumbnail {
      display: none; }

    .gsc-webResult.gsc-result {
      border: 0; }

    .gsc-thumbnail-inside,
    .gsc-url-top {
      padding: 0; }

    .gsc-thumbnail-inside {
      padding-bottom: 5px; }

    .gs-title {
      text-decoration: none; }

    .gsc-control-cse {
      padding: 0; }

    .gsib_a {
      padding: 10px 0;
      font-size: $font-size-base; }

    .gsc-input-box {
      border: 0;
      border-bottom: 1px solid #ccc; }

    .gsc-search-button {
      height: 32px;
      border: 0;
      padding: 0;
      margin: 0;
      background: transparent;

      svg {
        width: 20px;
        height: 20px;
        max-width: 20px;
        fill: #555; } }
 }    // sass-lint:enable class-name-format

  .uk-search {

    &.uk-search-large {
      @media (max-width: $mobile-max) {
        width: 100%; } }

    &.uk-search-block {
      .uk-search-input {
        border: 0;
        border-bottom: 1px solid #ccc;
        padding: 10px 30px 10px 0; } } }

  .load-more-button {
    display: block;
    margin: 30px auto 15px;
    border-bottom: 1px solid #999;
    color: #111;
    line-height: 30px;
    font-size: $font-size-tiny;
    background: none;

    &:hover {
      opacity: .5; } }

  .logo,
  .logo img {
    height: auto;
    width: 150px;
    color: #fff;

    @media (min-width: $desktop) {
      width: 240px; } }

  .uk-pagination {
    font-size: $font-size-tiny;

    @media (min-width: $tablet) {
      font-size: $font-size-small; }

    a {
      font-weight: 500; } }

  .uk-card-wrapper {
    &:hover {
      text-decoration: none;
      opacity: .9;

      img {
        filter: grayscale(100); } } }

  .uk-card {
    border-radius: $radius;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &.uk-card-default {
      box-shadow: none;
      background: #f5f5f5;
      color: $color-text; }

    &.featured {
      min-height: 250px;
      height: 100%;
      background: #444;

      .uk-label.as-wireframe {
        background: rgba(255, 255, 255, .3); }

      .uk-card-cover {
        width: 100%;
        height: 100%;
        opacity: .9;
        filter: contrast(.85); }

      .uk-card-summary {
        display: block; }

      .uk-card-body {
        background-image: linear-gradient(to bottom, transparent, #222);
        display: flex;
        position: absolute;
        bottom: 0;
        width: 100%;

        * {
          color: $color-white; }

        .uk-card-title {
          font-size: 1.1rem;
          font-weight: 500;
          align-items: flex-end;
          display: flex;
          flex-direction: row;
          padding-top: $gap * 2;

          @media (min-width: $tablet) {
            font-size: 1.2rem; }

          &+.uk-card-summary {
            margin-top: 10px; } }

        .uk-card-body-footer {
          border-top: 1px solid #999;
          margin-top: 10px;
          padding-top: 10px; } } }

    .uk-card-cover {
      height: 175px; }

    .uk-card-body {
      display: flex;
      flex: 1;
      flex-direction: column; }

    .uk-card-body,
    .uk-card-footer,
    .uk-card-header {
      padding: 10px; }

    .uk-card-title {
      font-size: .95rem;
      margin-bottom: 0;

      @media (min-width: $tablet) {
        flex: 0;
        font-size: 1rem;

        &+.uk-card-summary {
          flex: 1; } } }

    .uk-card-summary {
      width: 100%;
      margin-top: 15px;
      color: #555;

      @media (min-width: $tablet) {
        flex: 1; } }

    .uk-card-summary,
    .uk-card-body-footer {
      font-size: $font-size-small;
      line-height: 1.2; }

    .uk-card-body-footer {
      font-size: $font-size-tiny;
      width: 100%;
      text-transform: capitalize;
      margin-top: 5px;

      @media (min-width: $desktop) {
        border-top: 1px solid #999;
        padding-top: 10px;
        margin-top: 10px; } }

    .uk-card-footer {
      padding: 10px 20px; } }

  .repository-archive {
    .uk-card {
      &.featured {
        height: 250px;

        @media (min-width: $tablet) {
          height: 350px; } } }

    @media (max-width: $mobile-max) {
      .uk-grid-margin {
        margin-top: 10px; }

      .uk-card {
        display: flex;
        flex-direction: row;
        border-radius: 0;

        .uk-text-meta {
          font-size: $font-size-tiny; }

        .uk-card-summary {
          display: none; }

        .uk-card-body {
          flex-grow: 2; }

        .uk-card-cover {
          height: 100px;
          flex-grow: 1; }

        &.featured {
          height: 250px;
          background: #333;

          .uk-card-cover {
            height: auto; } } } } }

  .development-environment-bar {
    position: absolute;
    padding: 2px;
    text-align: center;
    background: rgba(255, 155, 0, .8);
    width: 100%;
    z-index: 1000;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    font-size: $font-size-tiny; }

  // Página inicial dos sites - zona de componentes
  main .alternate-rows {
      .row.no-background {
          padding-bottom: $gap / 2; }

      .row.no-background + .row.no-background {
          padding-top: 0;
          margin-top: -10px; } }

  hr {
    &.as-bar {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      height: 5px;
      margin-top: $gap;
      margin-bottom: $gap;
      width: 40%;
      background-color: #444;

      div {
        width: 50%; } } }

  // Foto dos utilizadors
  .user-picture {
      @extend %bg;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      border: 1px solid $ui-borders-dark;
      overflow: hidden;

      &:hover {
        filter: grayscale(100);

        .overlay {
          display: none; } }

      .expand {
        svg {
          width: 60%; } } }

  .block {
    .block-header {
      margin-bottom: 20px;

      .block-header-nav {
        display: flex;
        align-items: flex-end; } } }


  .filters-nav {
    font-weight: 500;

    label {
      font-size: $font-size-tiny; }

    .filters-label-break {
      line-height: 30px;
      text-transform: uppercase;
      color: $color-muted;
      font-weight: 400;
      margin-left: $gap;

      &+.uk-search {
        padding-left: 5px; } }

    .filter-element {

      a,
      button {
        @media (max-width: $mobile-max) {
          margin-left: -10px; }

        &.uk-active {
          background: $color-text;
          color: #fff;
          margin-left: 0;

          @media (min-width: $tablet) {
            display: inline-block; } } }

      &.filter-break {
        border-right: 1px solid $color-text; }

      &.filter-button {
        a,
        button {
          @media (max-width: $mobile-max) {
            margin: 15px 0 0; } } }

      &.filter-search {
        margin-left: 0;

        @media (max-width: $mobile-max) {
          width: 100%; }

        @media (min-width: $desktop) {
          margin-left: 30px; } }

      label {
        color: $color-label;
        margin-right: 5px;
        font-weight: 400;
        line-height: 30px;
        text-transform: uppercase; } }

    .uk-search-default {
      @media (max-width: $mobile-max) {
        width: 100%; } }

    input {
      &.uk-search-input {
        border: 0;
        height: 32px;
        font-weight: 400;
        border-bottom: 1px solid #ccc;
        padding-left: 0; } }

    button {
      line-height: 26px;
      padding: 0 10px;
      background: none;

      &[type='submit'] {
        &:not(.reset) {
          background: $color-text;
          color: #fff; } } } }

  // Título dos containers principais
  .container-title {
    align-items: center;
    display: flex;
    font-family: $font-family-primary;
    justify-content: space-between;
    max-width: 100%;
    font-weight: 400;
    margin: 20px 0;

    .col {
      justify-content: center;
      width: auto; }

    .uk-grid {
      align-items: center; } }

  %bg {
      background-position: center !important; //sass-lint:disable-line no-important
      background-repeat: no-repeat !important; //sass-lint:disable-line no-important
      background-size: cover !important; } //sass-lint:disable-line no-important

  // HRs
  $hr-height: (1: 1px, 2: 2px, 5: 5px, 10: 10px);
  @each $hr-key, $hr-var in $hr-height {

      .hr-#{$hr-key} {
          background-color: $color-fluc;
          height: $hr-var;
          width: 100%;

          &.color-primary {
              background: $color-primary; } } }


  //  ======================================================
  //  CARTÃO
  //  ► estilos base para os campos comuns dos cartões
  .site-menu {
      display: none;
      font-size: $font-size-small;
      margin: 0;
      padding: 0;

      ul {
          display: flex;
          flex-direction: column;
          //flex-direction: row
          position: relative;
          width: 100%;
          margin: 0;
          padding: 0;

          @media (min-width: $tablet) {
              flex-direction: row;
              flex-wrap: wrap;
              width: 100%; }

          li {
            padding: 0 $gap / 4 0 0;

            &.active {
              background: #eee; }

            .chevron-up {
              display: inline-block; }
            .chevron-down {
              display: none; }

            .main-submenu {
              background: #eee; } }

          li + li {
              //border-left: 1px solid $ui-borders
              padding-left: $gap / 4; } }

      .main-menu-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 10px;
          position: relative;
          width: 100%;
          cursor: pointer;

          @media (min-width: $desktop) {
              font-size: $font-size-small;
              padding: 8px;
              width: auto; }

          .chevron-up {
              display: none; }
          .chevron-down {
              display: inline-block; }

          &:last-of-type {
              .main-submenu {
                  right: 0; } } }

      .main-menu-text {
          align-items: center;
          display: flex;
          text-transform: uppercase;

          a {
              color: #444;

              &:hover {
                  text-decoration: none; } }

          span {
              display: inline-block;
              line-height: 1;
              vertical-align: middle;
              user-select: none; }

          span + span {
              margin-left: 5px; }

          svg {
              height: 16px;
              width: 16px; } }

      .main-submenu {
          display: none;
          flex-direction: column;
          line-height: 1.3;
          padding: $gap / 3 0;
          margin: 8px 0;
          min-width: 150px;
          text-align: left;
          width: 100%;
          z-index: 10;

          a {
              display: block;
              font-weight: 400;
              padding: 0 8px;
              color: $color-text;

              &:hover {
                  color: $color-link; } }

          li {
              padding: $gap / 4;
              user-select: none; }

          @media (min-width: $desktop) {
              margin-left: -8px;
              position: absolute; } } }

  %card {
      border-radius: $radius;
      color: $color-text;
      display: flex;
      flex-direction: column;
      font-weight: 400;
      height: 100%;
      background-color: rgba(230, 230, 230, .8);

      &:hover {
        text-decoration: none;
        transition: background-color .3s linear;
        opacity: .7;

        .cover {
          filter: grayscale(100);
          opacity: 1; } }

      .cover {
          @extend %bg;
          align-items: center;
          background-color: #c0c0c0;
          border-radius: $radius $radius 0 0;
          color: #fff;
          display: flex;
          justify-content: center;
          min-height: 100px;

          @media (min-width: $tablet) {
            height: 150px; } }

      .content {
          border-radius: 0 0 $radius $radius;
          display: flex;
          flex-direction: column;
          height: 100%;
          padding: 10px;

          @media (min-width: $desktop) {
            padding: 15px;
            height: auto; } }

      .title {
        height: 100%;
        font-weight: 500;
        font-size: $font-size-small;

        @media (min-width: $tablet) {
          font-size: 1rem; }

        &::first-letter {
          text-transform: uppercase; } } }


  //  ======================================================
  //  NOTÍCIAS
  //  ► cartão de notícias

  .card-news {
      @extend %card;

      .section {
          color: #828282;
          font-size: $font-size-tiny;
          font-weight: 600;
          margin-top: $gap / 2;
          text-transform: uppercase; } }

  .card-repo {
      @extend %card; }


  //  ======================================================
  //  EVENTOS
  //  ► cartão de eventos

  .card-events {
      @extend %card;

      .cover {
        align-items: stretch;
        display: flex;
        justify-content: flex-start;

        @media (min-width: 1200px) {
            min-height: 140px; } }

      &:hover {
          text-decoration: none;

          .title {
              text-decoration: underline; } }


      .icon {
          height: 20px;
          margin: 0 $gap / 2;
          width: 20px;

          @media (min-width: 640px) {
              height: 30px;
              margin: 0 $gap;
              width: 30px; } }

      .dates {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: $gap / 2;

          .spacer {
              font-size: $font-size-tiny;
              padding: 0 2px; }

          .date {
              display: flex;
              flex-direction: column;
              font-weight: 600;
              font-size: $font-size-base;

              .month {
                  text-transform: uppercase; }

              .year {
                  font-weight: 400; }

              &.as-block {
                  display: inline-block;

                  .month {
                      margin-left: 3px; } } } }

      .venue {
          color: #828282;
          font-size: $font-size-tiny;
          font-weight: 600;
          margin-top: 10px;
          line-height: 130%;
          text-transform: uppercase; } }


  //  ======================================================
  //  ACESSO RÁPIDO
  //  ► cartão de atalhos / faculdades do acesso rápido

  .card-shortcuts {
      @extend %card;
      margin-bottom: $gap / 2;
      background: none;

      .sigla {
          font-size: $font-size-base;
          font-weight: 300;
          text-transform: uppercase;

          @media (min-width: $tablet) {
            font-size: 1.3rem; } }

      .title {
          font-size: $font-size-base;
          font-weight: 400; }

      .bar {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          height: 5px;
          margin-bottom: 10px;
          width: 50%;

          div {
              width: 50%; } } }



  //  ======================================================
  //  DASBOARD
  //  ► cartão de atalhos / faculdades do acesso rápido

  .dashboard {
      &.container {
          margin-bottom: -15px; }

      .dashboard-card {
          @extend %card;
          font-size: $font-size-small;
          margin-bottom: $gap;
          background: none;
          height: auto;

          &:hover {

            .overlay {
              background: transparent; }

            a {
              background-color: #333;
              border-radius: $radius;

              &:hover {
                text-decoration: none; } } }

          .cover {
              border-radius: $radius;
              display: flex; }

          .overlay {
              align-items: center;
              align-self: flex-end;
              border-radius: 0 0 $radius $radius;
              color: #fff;
              display: flex;
              font-size: $font-size-small;
              font-weight: 600;
              justify-content: center;
              line-height: 1.2;
              padding: 20px;
              text-align: center;
              text-transform: uppercase;
              text-shadow: 0 0 2px #666;
              width: 100%;
              background-image: linear-gradient(to bottom, transparent, #222);

              @media (min-width: $desktop) {
                  font-size: $font-size-base;
                  padding: $gap; } } } }


  .menu-as-dashboard {
      .parent-title {
          font-size: 2.3rem;
          font-weight: 400;
          margin: 0 0 20px; } }


  //  ======================================================
  //  MODAIS
  //  ► estilos base para as modais

  %modal {
      background-color: rgba(51, 51, 51, 1);
      bottom: 0;
      margin: 0;
      color: #fff;
      display: none;
      flex-direction: column;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      transition: opacity 500ms ease-in;
      z-index: 99999;
      overflow-y: scroll;
      height: 100%;
      font-size: $font-size-small;
      padding: 0;

      @media (min-width: $desktop) {
          font-size: $font-size-base; }

      &:target {
          display: flex;
          pointer-events: auto; }

      a {
          color: $color-white;

          &:hover {
              color: #fff; } }

      .row {
          padding: 0; }

      .modal-content {
          ul {
              padding: 0;
              margin: 0; } }

      .modal-header {

          .close {
              padding-top: 5px;

              svg {
                  color: $color-white;
                  height: 14px;
                  width: 14px;

                  @media (min-width: $desktop-fhd) {
                      height: 24px;
                      width: 24px; } } } } }

  .modal {
      @extend %modal;

      &.modal-menu {
          li {
              padding: $gap / 6 0;
              font-size: $font-size-small;
              line-height: 1.5;

              @media (min-width: $tablet) {
                  font-size: $font-size-base; } }


          li:first-of-type {
              text-transform: uppercase;
              font-weight: 600; }

          .modal-title {
              display: block;

              h3 {
                  padding: 0;
                  margin: $gap / 2 $gap / 2 0;
                  text-transform: uppercase;
                  color: $ui-borders-medium;
                  font-size: 1.2rem;
                  font-weight: 500;

                  @media (min-width: $desktop) {
                    font-size: 1.5rem; } } } }


      &.modal-user {

          .user-header {
              text-align: center;
              color: $color-white; }

          .user-picture {
              height: 100px;
              width: 100px;
              border: 2px solid $color-white;
              margin: $gap / 2 auto;

              @media (min-height: $desktop) {
                  height: 150px;
                  width: 150px;
                  border-width: 4px; } }

          .user-fullname {
            padding: $gap / 4 $gap;
            font-size: 1.2rem;
            margin: 0;
            color: $color-white; }

          .user-email {
            padding: 0 $gap $gap / 3;
            margin: 0;
            color: #999; }

          .user-nav {
              display: flex;
              flex-direction: row;
              text-transform: uppercase;
              justify-content: center;
              font-size: $font-size-small;
              padding: $gap / 2 0;

              li {
                  margin-right: $gap / 2; }

              li:last-child {
                  margin-right: 0; } }

          .user-apps {
            margin: 0 auto;
            text-align: center;
            justify-content: center;
            align-items: center;
            max-width: 1024px;

            h3 {
              text-transform: uppercase;
              font-size: 1rem;
              color: $ui-borders-medium; }

            ul {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              padding: 0;
              margin: 0;

              li {
                display: flex;
                flex: 1;
                padding: 10px;
                font-size: $font-size-small;
                max-width: 50%;
                min-width: 50%;
                text-align: center;
                text-transform: uppercase;
                min-height: 100px;

                @media (min-width: $tablet) {
                  max-width: 16.66%;
                  min-width: 16.66%; }

                a {
                  align-items: center;
                  border: 1px solid rgba(0, 0, 0, .5);
                  border-radius: $radius;
                  color: rgba(255, 255, 255, .75);
                  display: flex;
                  flex: 1;
                  justify-content: center;
                  padding: 10px;
                  text-decoration: none;

                  &:hover {
                    background-color: rgba(0, 0, 0, .5);
                    color: rgba(255, 255, 255, 1);
                    transition: background-color .3s ease-in-out, color .3s ease-in-out; } } } } } }

      &.modal-search {

          .modal-content {
              padding: $gap;
              display: flex;
              justify-content: flex-end;

              form {
                  margin: 0 auto; }

              .custom-site-search {
                .uk-search-input {
                  border-bottom: 1px solid #999;
                  height: 50px; } }

              .uk-search-input {
                  font-size: 1.4rem;
                  color: $color-white;
                  padding-right: 30px;
                  height: 30px; }

              .uk-search-icon {
                  width: auto;
                  svg {
                      width: 25px;
                      height: 25px; } } } } }

  //  ==================================================
  //  SLIDERS
  //  ► controlos para os sliders dos repositórios e agregadores

  .slider-container {
      .slider-control {
          display: flex;
          flex-direction: row;
          line-height: 0;

          span {
              color: $color-text;
              margin: 0;
              padding: 5px;

              svg {
                  height: 16px;
                  width: 16px; } }

          div {
              //cursor: pointer

              &:hover {
                  background-color: #fff;
                  transition: background-color .5s linear; } }

          .slider-control-prev {
              border-radius: $radius 0 0 $radius;
              background: #eee; }

          .slider-control-next {
              border-radius: 0 $radius $radius 0;
              background: #eee; } } } }
