.ucpa-web,
.uc-site-layout {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  body {
    color: $color-text;
    font-family: $font-family-primary;
    font-size: $font-size-base-mobile;
    font-weight: $font-weight-base;
    line-height: 1.25;

    @media (min-width: $tablet) {
      font-size: $font-size-base; } }

  a {
    color: $color-link;
    font-weight: 400;
    text-decoration: none;

    &.active,
    &:hover {
      color: #000;
      transition: color .5s ease-out, text-decoration .5s ease-out; }

    &:hover {
      text-decoration: underline; }

    &.uk-button {
      text-decoration: none; }

    &.uk-text-bold {
      font-weight: 700; }

    &.uk-text-bolder {
      font-weight: 900; } }

  b,
  strong {
    font-weight: $font-weight-bold; }

  em {
    color: initial; }

  @for $i from 1 through 6 {
    h#{$i} {
      font-family: inherit;
      font-weight: $font-weight-bold;
      color: $color-text;
      line-height: 1.2;

      a {
        font-family: inherit;
        font-weight: inherit; } } }

  h1 {
    font-size: 1.3rem;

    @media (min-width: $tablet) {
      font-size: 1.7rem; } }

  h2 {
    font-size: 1.2rem;

    @media (min-width: $tablet) {
      font-size: 1.5rem; } }

  h3 {
    font-size: 1.1rem;
    font-weight: 400;

    @media (min-width: $tablet) {
      font-size: 1.25rem; } }

  h4 {
    font-size: 1rem;

    @media (min-width: $tablet) {
      font-size: 1.15rem; } }

  h5 {
    font-size: 1rem;
    font-weight: 400; }

  h6 {
    font-size: $font-size-base; }

  p + p,
  p + img {
    margin-top: $gap / 3;
    margin-bottom: $gap / 2; } }
