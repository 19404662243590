@media print {

  * {
    -webkit-print-color-adjust: exact !important; } //sass-lint:disable-line no-important

  @page {
    -webkit-print-color-adjust: exact;
    color-adjust: exact !important; //sass-lint:disable-line no-important
    size: A4 portrait;
    margin-left: 2cm;
    margin-right: 1cm;
    margin-top: 2cm;
    margin-bottom: 1cm; }

  .header-navigation,
  .main-banners,
  .site-menu,
  .footer-social,
  .footer-sitemap,
  .footer-nav,
  .cookies,
  .slider-control {
    display: none !important; } //sass-lint:disable-line no-important

  main {
    padding-top: 100px; }

  .cover,
  .content {
    border: 1px solid #ccc; }

  .cover {
    border-bottom: 0; }

  .content {
    border-top: 0; } }
