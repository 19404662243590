.ucpa-web,
.uc-site-layout {

  .row {
    padding: $gap / 2 0; }

  [v-cloak] {
    display: none; }

  .default-link-color,
  .default-link-color a {
          color: $color-link-pages; }

  code {
      font-size: $font-size-small; }

  button {
      &:focus {
          outline: 0; } }

  .text-transform-initial {
      text-transform: initial; }

  .help-block {
    background: #f8f8f8;
    color: #777;
    font-size: $font-size-small;
    padding: 10px;
    margin: 10px 0; }

  // UIKit

  .uk-nav-default,
  .uk-nav-primary {
      font-size: $font-size-base; }

  .uk-nav-primary {
    a {
      font-size: $font-size-small; } }

  .uk-button,
  .uk-button-primary {
    font-size: $font-size-tiny;
    padding: 0 10px;
    line-height: 30px;

    &.uk-button-primary {
      background: $color-text;
      color: #fff; }

    &.uk-button-default {
      background: #eee; }

    &.uk-button-text {
      font-size: $font-size-tiny;
      padding: 0; } }

  .uk-button-muted {
    background: #ccc;
    border: 1px solid #ccc; }

  .uk-button-small {
    font-size: $font-size-tiny;

    @media (min-width: $desktop) {
      font-size: $font-size-small; } }

  .uk-button-large {
    line-height: 38px; }

  .uk-modal-full {
      padding: 0; }

  .uk-text-meta,
  .uk-article-meta {
    color: #777;
    font-size: $font-size-small; }

  .text-micro {
    font-size: .6rem; }

  .text-muted {
    color: #777; }

  .uk-text-lead {
    font-size: 1.1rem;

    @media (min-width: $tablet) {
      font-size: 1.3rem; } }

  .uk-form-large {
      font-size: 1.2rem; }

  .uk-label {
      font-size: .6rem;
      padding: 0 2px;
      z-index: 1;

      &.uk-label-disabled {
        background: #808080; }

      &.as-wireframe {
        background: transparent;
        border: 1px solid #aaa;
        color: #888; }

      &.uk-label-new {
        padding: 0;
        font-size: .65rem;
        background: none;
        color: #faa05a; } }

  .uk-text-small {
      font-size: $font-size-small; }

  .uk-text-tiny {
      font-size: $font-size-tiny; }

  .uk-text-large {
      font-size: $font-size-lead; }

  .uk-notification {
      width: 100%;
      left: 0;
      top: 0;
      margin: 0;
      font-size: $font-size-small;

      &.uk-notification-message-success {
          color: #3cb371; }

      .uk-notification-message {
          font-size: $font-size-small;
          text-align: center;
          font-weight: 500;
          padding: 10px;

          @media (min-width: $tablet) {
            font-size: $font-size-base; }

          .uk-notification-close {
              top: 15px; } } }

  .uk-description-list {
      dt {
          font-size: $font-size-base; } }

  .uk-dropdown {
      padding: 10px 15px;

      .uk-dropdown-nav {
          font-size: $font-size-small;

          li {
            a {
              color: $color-text; } } } }

  .uk-subnav {
      align-items: center;

      li {
          a,
          span {
              font-size: $font-size-tiny; }

          a {
            color: #333;
            display: flex;
            align-items: center;

            &:hover {
              text-decoration: none; } } } }

  .cookies {
      background-color: rgba(100, 100, 100, .8);
      font-size: 85%;
      font-weight: bold;
      display: none;
      line-height: 1.2;
      padding: 0;
      position: fixed;
      bottom: 0;
      z-index: 1000;
      color: #fff;

      .container {
          padding: 10px 15px; }

      a {
          border-bottom: 1px solid#fff;
          font-weight: bold;
          color: #fff;

          &:hover {
              border: 0;
              text-decoration: none; } }

      .cookies-close-button {
          cursor: pointer; }

      @media (min-width: $desktop) {
          padding: 0;

          .container {
              padding: 15px 30px; } } }

  .trailbar {
    font-size: $font-size-small;
    padding: 0;
    height: 3px;

    @media (min-width: $desktop) {
      height: auto; }

    .container {
      padding: 10px;
      display: none;

      @media (min-width: $desktop) {
        padding: 15px 30px;
        display: flex; } }

    ul {
      margin: 0;
      padding: 5px 0;
      color: #fff;
      font-weight: 300;

      li {
        display: inline;

        a {
          color: #fff;
          opacity: .85;
          font-weight: 500; } } }


    .add-blur {
        filter: blur(3px); }

    // Colors

    .is-primary-color {
        color: $color-primary; }

    .is-gray {
        background-color: #f0f0f0;
        box-shadow: inset 0 $gap $gap $gap * -1 #ccc; }

    // Visibility

    .is-hidden {
        display: none; }

    .is-visible {
        display: flex; }

    .is-blurred {
        filter: blur(3px);
        height: 110%;
        left: -5%;
        position: absolute;
        top: -5%;
        width: 110%; }


    // Text elements

    //.lead
    //    @extend .text-lead
    //    margin: $gap 0

    //.label
    //    @extend label

    // Alignment

    .v-align-bottom {
        align-self: flex-end; } }


  table {
    th {
      font-size: $font-size-small; } }

  .table-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden; }

  .table-col {
    display: flex;
    flex: 1;
    flex-direction: column; }

  .table-cell {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    min-height: 5rem;
    padding: $gap / 2;
    text-align: center;

    &:first-of-type {
      background-color: #eee;
      font-size: 80%;
      height: 4rem;
      line-height: 1;
      text-transform: uppercase; } }

  .table-cell + .table-cell {
    border-left: 1px solid #eee; }

  .model {
    background-color: #ccc;
    justify-content: space-between;
    max-width: 5rem;

    .is-rotated {
      align-items: flex-end;
      padding: $gap * 2 $gap / 2;

      p {
        display: block;
        font-size: 2rem;
        font-weight: 600;
        transform: rotate(-90deg);
        white-space: nowrap; } } }

  .submodel {
    background-color: #ddd;
    max-width: 5rem;

    .is-rotated {
      align-items: flex-start;
      padding: $gap * 2 $gap / 2;

      p {
        display: block;
        font-size: 2rem;
        font-weight: 300;
        transform: rotate(-90deg);
        white-space: nowrap; } } } }
