.ucpa-web,
.uc-site-layout {

  legend {
    font-weight: 400;
    color: #777;
    font-size: $font-size-base; }

  .uk-fieldset {
    padding-bottom: $gap; }

  .uk-input,
  .uk-select,
  .uk-select:not([multiple]):not([size]) {
    height: 32px; }

  .uk-input,
  .uk-textarea,
  .uk-select {

    &:focus {
      border-color: #999;
      color: #444;
      transition: border-color .3s linear, color .3s linear; } }

  .uk-form-label {
    font-size: $font-size-small;
    margin-top: $gap / 2;
    color: #777; }

  .uk-legend {
    font-size: $font-size-lead;
    font-weight: $font-weight-base; }

  form {
    .uk-alert-danger {
      font-size: $font-size-small; }

    &.is-collapsible {
      div {
        display: none; } }

    fieldset,
    legend {
      &.is-visible {
        div {
          display: block; }
        .uk-grid {
          display: flex; } } }

    .uk-icon {
      border-radius: $radius; } }

  .submit {
    color: rgba(255, 255, 255, .8);
    margin-top: $gap;
    text-transform: uppercase;

    &:hover {
      background-color: #000;
      color: rgba(255, 255, 255, 1);
      transition: background-color .3s linear, color .3s linear; }

    &:focus,
    &.loading {
      background-color: #000;
      background-image: url('../images/loading.svg');
      background-position: right -10px center;
      background-repeat: no-repeat;
      background-size: 50%;
      color: rgba(255, 255, 255, 1);
      display: none;
      padding-right: 40px !important; //sass-lint:disable-line no-important
      transition: background-color .3s linear, color .3s linear; } } }
