.ucpa-web,
.uc-site-layout {

  header {
    color: #eee;
    position: fixed;
    top: 0;
    z-index: 999;
    font-weight: 400;
    background-color: rgba(51, 51, 51, .9);
    font-size: $font-size-base;

    &:hover {
      background-color: #2c2c2c;
      transition: background-color .3s ease-in-out; }

    &.small {
      @media (min-width: $tablet) {
        height: auto; }

      .site-menu.header-menu {
        display: none; }

      .header-navigation {
        border: 0; } }

    &.expanded {
      height: 100%;
      background-color: rgba(51, 51, 51, 1);
      overflow-y: scroll;

      .header-navigation {
        @media (min-width: $tablet) {
          border-top: 1px solid #999;
          margin-top: -1px; }

        .site-menu {
          margin-top: 10px; } }

      .header-brand {
        margin: 0; }

      .site-menu + .site-menu {
        margin-top: $gap / 2; }

      .site-menu {
        display: flex;

        @media (min-width: $desktop) {
          font-size: $font-size-small;
          width: auto; }

        ul {
          li {
            .main-submenu {
              background: none; } } } } }

    ul {
      padding: 0;
      margin: 0; }

    .row {
      padding: 0; }

    .container {
      @media (min-width: $desktop) {
        padding: 0 $gap; } }

    .col {
      justify-content: center; }

    a {
      color: #ccc;
      font-weight: 400;

      &:hover {
        color: #fff; } }

    .site-menu-toggle {
      align-items: center;
      display: flex;

      label {
        font-size: $font-size-small;
        margin-right: 5px; }

      @media (min-width: $desktop) {
        display: none; } }

    .site-menu {
      justify-content: flex-end;
      width: 100%;
      margin: 0;

      @media (min-width: $desktop) {
        display: none; }

      li {
        &.active {
          background: #2c2c2c;

          .main-submenu {
            background: #2c2c2c; } } }

      .main-menu-item {
        &.active {
          color: $color-white; } }

      .main-submenu {
        a {
          color: #ccc;

          &:hover {
              color: #fff; } }

        span {
          color: #ccc; }

        @media (min-width: $desktop) {
          font-size: $font-size-small; } } }

    .header-menu {
      ul {
        justify-content: flex-end;

        @media (min-width: $desktop) {
          justify-content: center; } }

      @media (min-width: $desktop) {
        display: flex; } }

    .header-brand {
      margin: 0;
      height: 40px;
      justify-content: center;
      display: flex;

      @media (min-width: $desktop) {
        height: 60px; }

      &.container {
        margin: 0;
        padding: 5px 10px;

        @media (min-width: $desktop) {
          padding: 5px $gap; } }

      a {
        display: inline-block; } }

    .header-navigation {
      display: none;
      flex-direction: row;
      justify-content: space-between;
      position: relative;
      margin: 0;
      padding-top: 0;

      .site-menu .main-menu-text a {
        color: #eee; }

      .col {
        padding: 0 $gap / 2; }

      &.container {
        @media (min-width: $tablet) {
          display: flex;
          padding: 0; }

        @media (min-width: $desktop) {
          flex-direction: row-reverse; } } }

    .header-tools {
      align-items: center;
      display: flex;
      line-height: 1;
      justify-content: flex-end;
      padding: 0 0 $gap / 2;
      text-transform: uppercase;
      font-size: $font-size-small;
      border-bottom: 1px solid #999;
      margin-bottom: 10px;

      @media (min-width: $tablet) {
        position: absolute;
        right: 50px;
        top: -32px;
        border: 0;
        margin-bottom: 0;
        padding: 0;
        min-height: 25px;

        .user-picture {
          height: 25px;
          width: 25px; } }

      @media (min-width: $desktop) {
        top: -50px;
        padding: 0;
        font-size: $font-size-small;
        min-height: 40px;

        .user-picture {
          height: 30px;
          width: 30px; } }

      @media (min-width: $desktop) {
        right: $gap; }

      a {
        color: $ui-borders;

        &:hover {
          color: inherit; } }

      .quick-access {
        @media (min-width: $tablet) {
          padding-right: 15px; } }

      .language {
        a + a {
          margin-left: $gap / 2; }

        .active {
          font-weight: 700;
          color: $color-white; } }

      ul {
        display: flex;
        flex-direction: row;
        width: 100%; }

      li {
        align-items: center;
        display: flex; }

      li:first-child {
        flex: 1;
        display: flex;
        justify-content: flex-start; }

      li:last-child {
        padding-right: 0; }

      li + li {
        border-left: 1px solid $ui-borders-dark;
        padding-left: $gap / 2;
        padding-right: $gap / 2; }

      span {
        display: inline-block;
        line-height: 1;
        vertical-align: middle; }

      .icon svg {
        height: 16px;
        width: 16px;
        margin-left: $gap / 4;

        @media (min-width: $desktop) {
          height: 20px;
          width: 20px; } }

      .search .icon svg {
        margin-left: 0; } } }

  footer {
    background-color: #fff;
    color: $color-text;
    font-family: $font-family-primary;
    font-size: $font-size-tiny;
    line-height: 1.2;
    margin-top: 15px;

    @media (min-width: $desktop) {
      margin-top: 30px; }

    ul {
      padding: 0;
      margin: 0; }

    .row {
      padding: $gap / 2 0;

      @media (min-width: $tablet) {
        padding: 15px 0 0; }

      .container {
        padding: 4px $gap / 2; } }

    .footer-subtitle {
      padding: 15px 0 10px;
      color: #4f4f4f;
      font-size: $font-size-tiny;
      font-weight: 400;
      text-align: center;
      text-transform: uppercase;

      @media (min-width: $tablet) {
        font-size: $font-size-small; }

      svg {
        width: 14px;
        height: 14px; } }

    a {
      color: $color-text;
      font-weight: 400; }

    li {
      padding: 2px 0; }

    li:first-of-type {
      text-transform: uppercase;
      font-weight: 700; }

    .footer-social {
      ul {
        display: flex;
        flex-direction: row;
        justify-content: center; }

      li + li {
        margin-left: $gap / 2; }

      .uk-icon {
        svg {
          height: 18px;
          width: 18px;
          color: $color-text;

          @media (min-width: $desktop) {
            height: 24px;
            width: 24px; } } }

      @media print {
        display: none; } }

    .footer-sitemap {
      .col {
        padding: 5px 0;

        @media (min-width: $desktop) {
          max-width: 14%;
          padding: 5px; } }

      .footer-content {
        display: none; }

      &.expanded {
        .footer-content {
          display: flex;
          color: #555;

          a {
            color: #555; }

          li {
            @media (min-width: $tablet) {
              padding: 4px 0; } } } }

      @media print {
        display: none; } }

    .footer-nav {
        padding: $gap / 2 $gap;
        font-size: .7rem;

        @media (min-width: $desktop) {
            padding: $gap / 2; }

        ul {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center; }

        li {
            display: inline-block; }

        a {
            font-weight: 400;
            text-transform: uppercase;
            padding: 0 5px; }

        li + li {
          &::before {
            content: '\00a0/\00a0'; } } }

    .footer-logos {
      align-items: center;
      text-align: center;
      font-size: $font-size-small;
      font-weight: 300;
      padding: 0;

      .col {
          display: none;

          &.footer-copyright {
              display: flex;
              font-size: .65rem;
              padding: 0; }

          @media (min-width: $desktop) {
              display: flex; } }

      img {
          align-self: center;
          max-width: 200px;

          @media (min-width: $desktop) {
              margin: 0; } } } }

  .article {
    @media (min-width: $tablet) {
      a {
          color: $color-link-pages; }

      p {
          overflow: hidden;

          &+br {
            display: none; } } } }

  .main-banners {

      .metadata {
          margin: 0;
          background-image: linear-gradient(to bottom, transparent, #222);
          color: $color-white;

          a {
              color: inherit;

              &:hover {
                  color: #fff; }

              h2,
              p {
                @media (min-width: $tablet) {
                  max-width: 70%; }

                @media (min-width: $desktop) {
                  max-width: 600px; } } }

          h2 {
            margin-bottom: $gap / 3;
            color: #fff;
            font-weight: 500;

            @media (min-width: $tablet) {
              font-size: 1.5rem; }

            @media (min-width: $desktop) {
              font-size: 1.7rem; } }

          p {
            font-size: $font-size-small;
            text-transform: uppercase;
            margin: 0;

            @media (min-width: $tablet) {
              font-size: 1.1rem; } } }

      .button-play {
          color: #fff; }

      .uk-dotnav {
          margin: -$gap / 2 auto 0 auto;
          position: absolute;
          width: 100%;
          z-index: 200;

          a {
              background-color: rgba(255, 255, 255, .5);
              border: 0;
              border-radius: $radius;
              height: 4px;
              width: $gap / 2;

              @media (min-width: $desktop) {
                  height: 4px;
                  width: $gap; } }

          .uk-active {
              a {
                  background-color: #fff; } } } }

  .main-banner-empty {
      height: 50px;

      @media (min-width: $desktop) {
        height: 60px;

        &.home {
          height: 113px; } } }

  .main-banner-small {
      height: 200px;

      @media (min-width: $desktop) {
          height: 400px; }

      &.min {
          height: 150px;
          overflow: hidden;

          .cover {
              height: 100%;
              filter: blur(5px);
              transform: scale(1.1); }

          @media (min-width: $desktop) {
              height: 250px; } } }

  .site-hr {
    height: 5px;
    border: 0;
    background-color: $color-white;
    margin: 0;

    @media (min-width: $desktop) {
      height: 10px; } }

  .site-header {
    font-family: $font-family-primary;
    padding: 0;

    @media (min-width: $desktop) {
      padding: 5px 0 0; }

    .site-title {
      display: flex;
      justify-content: space-between;

      @media (min-width: $desktop) {
        margin-bottom: 10px; } }

    .site-menu {
      display: none;

      @media (min-width: $desktop) {
        display: flex;
        margin-left: -8px; } }

    .site-trailbar,
    .site-title-trailbar {
      font-size: 1.1rem;
      line-height: 1.2;

      @media (min-width: $tablet) {
        font-size: 1.5rem; }

      > div {
        position: relative; }

      a {
        color: inherit; } } }

  .agendauc-component {
      display: none; } }
