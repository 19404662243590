.ucpa-web,
.uc-site-layout {

  * {
    box-sizing: border-box; }

  html {
    overflow-x: hidden;
    overflow-y: scroll; }

  html,
  body,
  header,
  main,
  footer,
  .row,
  .container,
  .col {
    display: flex;
    width: 100%; }

  &.fluid-layout {
    .row {
      .container {
        @media (min-width: $desktop) {
          width: 100%;
          max-width: 1680px; } } } }

  body {
    flex-direction: column;
    min-height: 100vh; }

  main {
    box-sizing: content-box;
    flex: 1 0 auto;
    flex-direction: column; }

  footer {
    flex-direction: column; }

  .row {
    align-items: center;
    flex-direction: column;
    padding: $gap 0;

    .container {
      flex-direction: column;
      margin: 0 auto;
      padding: 10px;

      @media (min-width: $desktop) {
        max-width: $desktop-hd;
        padding: 10px $gap; } } }

  .col {
    flex-direction: column;
    padding: $gap / 2; }

  .is-auto {
    width: auto; }

  .is-full {
    flex: 1; }

  .is-fixed {
    overflow: hidden; }

  %grid,
  .grid {
    display: flex;
    flex-direction: row !important; //sass-lint:disable-line no-important
    flex-wrap: wrap;

    .col {
      flex-shrink: 0; } }

  .grid-11 {
    @extend %grid;

    .col {
      max-width: 100%; } }

  .grid-12 {
    @extend %grid;

    .col {
      max-width: 100%; }

    @media (min-width: $tablet) {
      .col {
        max-width: 50%; } } }

  .grid-13 {
    @extend %grid;

    .col {
      max-width: 100%; }

    @media (min-width: $tablet) {
      .col {
        max-width: 33.33%; } } }

  .grid-14 {
    @extend %grid;

    .col {
      max-width: 100%; }

    @media (min-width: 568px) {
      .col {
        max-width: 25%; } } }

  .grid-16 {
    @extend %grid;

    .col {
      max-width: 100%; }

    @media (min-width: 568px) {
      .col {
        max-width: 33.33%; } }

    @media (min-width: 960px) {
      .col {
        max-width: 25%; } }

    @media (min-width: 1200px) {
      .col {
        max-width: 16.66%; } } }

  .grid-22 {
    @extend %grid;

    .col {
        max-width: 50%; } }

  .grid-23 {
    @extend %grid;

    .col {
      max-width: 50%; }

    @media (min-width: 568px) {
      .col {
        max-width: 33.33%; } } }

  .grid-24 {
    @extend %grid;

    .col {
      max-width: 50%; }

    @media (min-width: 568px) {
      .col {
        max-width: 33.33%; } }

    @media (min-width: 960px) {
      .col {
        max-width: 25%; } } }

  .grid-26 {
    @extend %grid;

    .col {
      max-width: 50%; }

    @media (min-width: 568px) {
      .col {
        max-width: 33.33%; } }

    @media (min-width: 960px) {
      .col {
        max-width: 25%; } }

    @media (min-width: 1200px) {
      .col {
        max-width: 16.66%; } } }

  .uk-width-base {
    width: 200px; } }
